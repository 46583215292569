import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { getUsers, createUser, updateUser, getOrders, createUserDemand, createApprovalUser } from "app/services/UserService";
import { NotificationManager } from "react-notifications";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCESS = 'GET_USERS_SUCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_USER_ORDERS_BEGIN = 'GET_USER_ORDERS_BEGIN';
export const GET_USER_ORDERS_SUCESS = 'GET_USER_ORDERS_SUCESS';
export const GET_USER_ORDERS_FAILED = 'GET_USER_ORDERS_FAILED';

export const fetchUsers = (params) => dispatch => {
  dispatch({
    type: GET_USERS_BEGIN
  });
  getUsers(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_USERS_SUCESS,
        payload: resp?.data?.data
      })
    } else {
      dispatch({
        type: GET_USERS_SUCESS,
        payload: []
      })
    }
  })
};

export const createNewUser = (payload) => dispatch => {
  return createUser(payload).then((resp) => {
    if (resp?.data) {
      NotificationManager.success('Record updated successfully')
      return resp?.data
    } else {
      NotificationManager.error('Fail to update record, Please try again.')
    }
  }).catch(e => {
    NotificationManager.error('Fail to update record, Please try again.')

  })
};

export const createNewApprovalUser = (payload) => dispatch => {
  return createApprovalUser(payload).then((resp) => {
    if (resp?.data) {
      NotificationManager.success('Record updated successfully')
      return resp?.data
    } else {
      NotificationManager.error('Fail to update record, Please try again.')
    }
  }).catch(e => {
    NotificationManager.error('Fail to update record, Please try again.')

  })
};

export const createUsersDemand = (payload) => dispatch => {
  return createUserDemand(payload).then((resp) => {
    if (resp?.data) {
      NotificationManager.success('Demand Created Successfully')
      return resp?.data
    } else {
      NotificationManager.error('Fail to create record, Please try again.')
    }
  }).catch(e => {
    NotificationManager.error('Fail to create record, Please try again.')

  })
};

export const updateNewUser = (payload) => dispatch => {
  return updateUser(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success('Record updated successfully')
        return resp?.data
      } else {
        console.log(resp?.data?.error);
        NotificationManager.error('Fail to update record, Please try again.')
      }
    })
    .catch(e => {
      NotificationManager.success('Fail to update record, Please try again.')

    })
};

export const fetchUserOrders = (params) => dispatch => {
  dispatch({
    type: GET_USER_ORDERS_BEGIN
  });

  getOrders(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_USER_ORDERS_SUCESS,
        payload: resp?.data
      })
    } else {
      dispatch({
        type: GET_USER_ORDERS_FAILED,
        payload: {}
      })
    }
  })
};


export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    history.push({
      pathname: "/login"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
