import React, { Component } from "react";
import './style.scss';
import { Breadcrumb } from "@gull";
import SimpleCard from "@gull/components/cards/SimpleCard";
import { Tab, Nav, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getSavedSearch, getRecentSearch } from '../../../redux/actions/SearchDiamondAction';
import { UserDashboardStats } from '../../../redux/actions/MiscActions';
import moment from 'moment';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { contactInfo, banKInfo } from "environment/env";
import Spin from "@gull/components/Spin";
import Image from "app/GullLayout/SharedComponents/Image";
import { fetchFeaturedDiamond } from '../../../redux/actions/DiamondActions';
import { storageUrl } from 'environment/env';
import RaiseDemand from "app/GullLayout/SharedComponents/RaiseDemand";
import { currencyConterver, FILE_FORMATS } from '@utils'

class Dashboard extends Component {
  state = {
    windowWidth: window.innerWidth,
    cardList: [
      {
        icon: "new-diamonds-icon",
        subtitle: "New Diamonds",
        title: "0",
        onClick: e => this.props.history.push('new-diamonds')
      },
      {
        icon: "total-enquiries-icon",
        subtitle: "Total Enquiries",
        title: "0"
      },
      {
        icon: "recommended-icond",
        subtitle: "Recommendations",
        title: "0",
        onClick: e => this.props.history.push('recommended')
      },
      {
        icon: "i-Money-2",
        usei: true,
        title: "$ 0",
        subtitle: "Pending Amount",
        onClick: e => this.openBankModal()
      },
    ],
    openBankModal: false,
    openEnquiryModal: false,
    enquiryPayload: {
      remark: ''
    }
  };
  componentDidMount() {
    this.props.UserDashboardStats()
    this.props.fetchFeaturedDiamond({ featured: true, limit: 5 })
    if (!this.props.searchList) {
      this.props.getSavedSearch()
    }
    if (!this.props.recentList) {
      this.props.getRecentSearch()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userStats !== prevProps.userStats) {
      const { userStats } = this.props
      this.setState({
        cardList: [
          {
            icon: "new-diamonds-icon",
            subtitle: "New Diamonds",
            title: userStats.newDiamondCount,
            onClick: e => this.props.history.push('new-diamonds')
          },
          {
            icon: "total-enquiries-icon",
            subtitle: "Total Enquiries",
            title: userStats.totalEnquiries
          },
          {
            icon: "recommended-icond",
            subtitle: "Recommendations",
            title: userStats.recommandedCount,
            onClick: e => this.props.history.push('recommended')
          },
          {
            icon: "i-Money-2",
            usei: true,
            title: currencyConterver(Number(userStats.totalPendingAmount)),
            subtitle: "Pending Amount",
            onClick: e => this.openBankModal()
          },
        ]
      })
    }
  }

  handlePageClick = data => {
    let pageNumber = data.selected;
    let offset = Math.ceil(pageNumber * this.props.perPage);
    this.setState({ offset: offset }, () => {
      console.log(data);
    });
  };

  getUserStatusClass = status => {
    switch (status) {
      case "active":
        return "badge-success";
      case "delivered":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "not delivered":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  getStatusTextColor = status => {
    switch (status) {
      case "In Stock":
        return "text-success";
      case "Low Stock":
        return "text-warning";
      case "Out of Stock":
        return "text-danger";
      default:
        return "text-primary";
    }
  };

  openBankModal = () => {
    this.setState({
      openBankModal: !this.state.openBankModal
    })
  }

  render() {
    let {
      cardList = [],
      openBankModal,
      // lastMonthSummery = [],
      // topAuthorList = [],
      // newUserList = [],
      // topProductList = []
    } = this.state;
    const { user, searchList, recentList, savedSeachloading, recentSeachloading, featuedLoading, featuredDiamondList } = this.props;
    const buttonList = [
      <Button
        key={3}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: 'right' }}
        onClick={e => this.openBankModal()}
      >
        Payment Details
    </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: 'right' }}
        onClick={e => this.props.history.push('/my-order')}
      >
        My Orders
    </Button>,
      <RaiseDemand />
    ]
    const buttonListmerge = [
      <Button
        key={3}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: 'right' }}
        onClick={e => this.openBankModal()}
      >
        Payment Details
    </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: 'right' }}
        onClick={e => this.props.history.push('/my-order')}
      >
        My Orders
    </Button>,
      <RaiseDemand className="parchdisplay" />
    ]
    var switchlist = []
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge]
    }
    return (
      <div className="report dashboard">
        <Breadcrumb
          routeSegments={[
            { name: "Dashboard", path: "/dashboard" }
          ]}
          extra={switchlist}
          patchingclass={'pad-2rem-petch'}
          switchlist_bc={buttonList}
        ></Breadcrumb>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Spin loading={this.props.userStatsloading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-4 greet-card">
                    <div className="card-body">
                      <div className="card-text">
                        <p className="h4 greeting-text">Welcome, {user.name}</p>
                        <div className="row">
                          <div className="col-md-6 h5">{user.company_name}</div>
                          <div className="col-md-6 text-right login-time">Last logged in : {moment(this.props.user.last_login_date).format('DD MMM, YYYY hh:mm A')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {cardList.map((card, index) => (
                  <div key={index} className="col-md-6">
                    <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4" style={{ cursor: 'pointer' }} {...{ onClick: card.onClick }}>
                      <div className="card-body text-center">
                        <div className="imagecard">
                          {card.usei ?
                            <i className={card.icon}></i>
                            : <img className="nav-icon" alt="loading" src={`${process.env.PUBLIC_URL}/assets/images/dashboard-icon/${card.icon}.svg`} />}
                        </div>
                        <div className="ml-5">
                          <p className="text-muted mt-2 mb-0 text-capitalize">
                            {card.subtitle}
                          </p>
                          <p className="lead text-primary text-24 mb-2 text-capitalize">
                            {card.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="card-text">
                        <p className="greeting-text">Contact for enquiries</p>
                        <div className="row">
                          <div className="col-md-6 h6"> <i className="i-Administrator" /> Name : {contactInfo.name}</div>
                          <div className="col-md-6 h6"> <i className="i-Telephone" /> Phone : {contactInfo.mobile}</div>
                          <div className="col-md-6 h6"> <i className="i-Envelope-2" /> Email : {contactInfo.email}</div>
                          <div className="col-md-6 h6">
                            <img className="small-icon-contact" alt="loading" src={`${process.env.PUBLIC_URL}/assets/images/dashboard-icon/whatsapp-icon.svg`} /> WhatsApp : {contactInfo.whatsappNo}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">

              <div className="col-md-12">
                <Spin loading={featuedLoading}>
                  <div className="card mb-4">
                    <div className="card-header featured-header">Featured</div>
                    <div className="card-body">
                      {
                        featuredDiamondList?.length !== 0
                          ? <div className="card-text">
                            <AliceCarousel
                              duration={800}
                              dotsDisabled={window.innerWidth > 600 ? true : false}
                              ref={el => (this.Carousel = el)}
                              buttonsDisabled={true}
                              mouseTrackingEnabled
                              autoPlay={true}
                              playButtonEnabled={false}
                              autoPlayInterval={8000}
                              stopAutoPlayOnHover={true}
                              disableDotsControls={window.innerWidth > 600 ? true : false}
                              infinite={true}
                              disableButtonsControls={true}
                            >
                              {!!featuredDiamondList && featuredDiamondList.map((diamond, textIndex) => {
                                return (
                                  <div key={textIndex}>
                                    <div className="inner-slide-show-layout" onClick={e => this.props.history.push(`/diamond/${window.btoa(diamond.id)}`)}>
                                      <div className={`image-layout ${!diamond?.img_url ? 'pl-5 pr-5' : ''}`}>
                                        {
                                          !!diamond?.img_url && FILE_FORMATS.find(i => { return diamond?.img_url ? diamond?.img_url.includes(i) : false })
                                            ? <Image src={`${storageUrl}${diamond?.img_url}`} class="img-fluid" alt={diamond?.stone_id} />
                                            : !!diamond?.img_url ? <iframe title="image" src={diamond?.img_url} width={'100%'} /> : <Image width="100px" className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                        }
                                        {/* <Image src={`${storageUrl}${diamond?.img_url}`} alt="" /> */}
                                      </div>
                                      <div className="content-layout-1">
                                        <div className="name" title="shape carat color clarity cut polish symmetry fluo">{diamond.carat || '-'}CTS {diamond.shape || '-'} <br /> {diamond.color || '-'} {diamond.clarity || '-'} {diamond.cut || '-'} {diamond.polish || '-'} {diamond.symmetry || '-'} {diamond.fluo || '-'} <br /> {diamond.j_discount || 0}% discount</div>
                                        <div className="details" title="stone no">{diamond.stone_id}</div>
                                        <div className="details">{currencyConterver(Number(diamond.j_total_amount)) || '-'}</div>
                                        <div className="certificate">{diamond.lab}</div>
                                      </div>
                                      {window.innerWidth > 600 && (
                                        <div className="content-layout-2">
                                          <div className="upcoming">Up Next</div>
                                          <div className="name">{featuredDiamondList[((featuredDiamondList.length - 1) === textIndex) ? 0 : (textIndex + 1)]?.stone_id || '-'}</div>
                                          <div className="price">{currencyConterver(Number(featuredDiamondList[((featuredDiamondList.length - 1) === textIndex) ? 0 : (textIndex + 1)]?.j_total_amount)) || '-'}</div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </AliceCarousel>

                            {window.innerWidth > 800 && (
                              <center>
                                <img className="pad" onClick={() => this.Carousel.slidePrev()} src={`${process.env.PUBLIC_URL}/assets/images/left-arrow.svg`} alt="arrow left" />
                                <img className="pad" onClick={() => this.Carousel.slideNext()} src={`${process.env.PUBLIC_URL}/assets/images/right-arrow.svg`} alt="arrow left" />
                              </center>
                            )}
                          </div>
                          : <div className="card-text">
                            <div className="text-center m-5">
                              <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </Spin>
              </div>

              <div className="col-md-12">
                <SimpleCard>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="d-flex px-2">
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        <Nav.Link eventKey="first">Saved Searches</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        <Nav.Link eventKey="second">Recently Searched</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Spin loading={savedSeachloading}>
                          {
                            searchList?.length
                              ? searchList.map((item, index) => (
                                <div
                                  onClick={e => this.props.history.push('/report', { filters: JSON.parse(item.data_str)?.payload || {}, inputCarat: JSON.parse(item.data_str)?.inputCarat || [] })}
                                  key={index}
                                  className="d-flex border justify-content-between p-3 mb-3"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="flex-grow-1 w-50">
                                    <span className="text-small">
                                      {item.title}
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="text-small">
                                      {moment(item.updated_at).format('DD-MMM-YYYY')}
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="text-small">
                                      {moment(item.updated_at).format('hh : ss A')}
                                    </span>
                                  </div>
                                  {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                                </div>
                              ))
                              : (
                                <div className="d-flex justify-content-center" >
                                  <div className="text-center">
                                    <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                  </div>
                                </div>
                              )
                          }
                        </Spin>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Spin loading={recentSeachloading}>
                          {
                            recentList?.length
                              ? recentList.map((item, index) => (
                                <div
                                  onClick={e => this.props.history.push('/report', { filters: JSON.parse(item.data_str)?.payload || {}, inputCarat: JSON.parse(item.data_str)?.inputCarat || [] })}
                                  key={index}
                                  className="d-flex border justify-content-between p-3 mb-3"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="flex-grow-1 w-50">
                                    <span className="text-small">
                                      {item.title}
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="text-small">
                                      {moment(item.updated_at).format('DD-MMM-YYYY')}
                                    </span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="text-small">
                                      {moment(item.updated_at).format('hh : ss A')}
                                    </span>
                                  </div>
                                </div>
                              ))
                              : (
                                <div className="d-flex justify-content-center" >
                                  <div className="text-center">
                                    <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                  </div>
                                </div>
                              )
                          }
                        </Spin>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </SimpleCard>
              </div>

            </div>
          </div>

        </div>

        <AppModal
          show={openBankModal}
          title={'Payment Details'}
          footer={[<Button
            key={3}
            variant={`primary`}
            className="btn-rounded m-1 text-capitalize"
            style={{ float: 'right' }}
            onClick={e => this.openBankModal()}
          >
            Close
        </Button>]}
          size="md"
          onHide={() => this.openBankModal()}
        >
          <div className="row">
            <div className="col-md-12 h5">Bank Details:</div>
            {
              Object.keys(banKInfo).map(info => {
                return <div className="col-md-12">
                  {info?.toUpperCase()}: {banKInfo[info]}
                </div>
              })
            }


            <div className="col-md-12 h6 mt-3"> For any query, Please contact us:</div>
            <div className="col-md-6 h6"> <i className="i-Telephone" /> Phone : {contactInfo.mobile}</div>
            <div className="col-md-6 h6">
              <img className="small-icon-contact" width={15} alt="loading" src={`${process.env.PUBLIC_URL}/assets/images/dashboard-icon/whatsapp-icon.svg`} /> WhatsApp : {contactInfo.whatsappNo}
            </div>
          </div>
        </AppModal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  searchList: state.searchDiamond.savedSearches,
  savedSeachloading: state.searchDiamond.savedSeachloading,
  recentList: state.searchDiamond.recentSearches,
  recentSeachloading: state.searchDiamond.recentSeachloading,
  settings: state.layout.settings,
  userStats: state.misc?.userStats,
  userStatsloading: state.misc?.userStatsloading,
  featuredLoading: state.diamond.loading,
  featuredDiamondList: state.diamond.data,
});
export default connect(mapStateToProps, { getSavedSearch, UserDashboardStats, getRecentSearch, fetchFeaturedDiamond })(Dashboard);
