import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { envType } from 'environment/env';
import { setUpCurrencyConvertion } from "@utils";

if (envType === 'prod') {
    console.log = () => { }
}
setUpCurrencyConvertion()
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!('Notification' in window)) {
    console.log('This browser does not support notifications!');
} else {
    Notification.requestPermission(status => {
        console.log('Notification permission status:', status);
    });
}
serviceWorker.unregister();
