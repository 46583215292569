/* eslint-disable */
import React, { Component } from 'react'
import './SearchDiamond.scss';
import { Breadcrumb, SimpleCard, Loading } from '@gull';
// import { data } from './dumb.json';
import { /*Badge,*/ Button, ToggleButton, ToggleButtonGroup, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getSearchDiamondForm, getSavedSearch, postSavedSearch } from '../../../redux/actions/SearchDiamondAction';
import { withRouter } from 'react-router-dom';
import AppModal from 'app/GullLayout/SharedComponents/modal/Modal';
import { Formik } from 'formik';
import * as yup from "yup";
import DiamondShape from './diamond-shapes';
import moment from 'moment';
import { fetchCountries } from '../../../redux/actions/MiscActions';

class SearchDiamond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            payload: {},
            addTag: ['carat'],
            openModal: false,
            saveFormValues: {
                title: ''
            },
            inputCarat: [],
            carat: {}
        }
        this.caratForm = React.createRef();
        this.caratTo = React.createRef();
        if (props.formList)
            props.getSearchDiamondForm(props.searchType)

        if (!props.searchList) {
            props.getSavedSearch()
        }
        window.scrollTo(0, 0);
        if (!this.props.countryList)
            this.props.fetchCountries({})
    }

    componentDidMount() {
        if (this?.props?.location?.state?.filters) {
            this.setState({
                payload: this?.props?.location?.state?.filters,
                inputCarat: this?.props?.location?.state?.inputCarat ? this?.props?.location?.state?.inputCarat : []
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props?.formList?.length !== prevProps?.formList?.length) {
            this.setState({
                formData: this.props.formList
            })
        }
        if (this.props?.searchList?.length !== prevProps?.searchList?.length) {
            this.setState({
                openModal: false
            })
        }
    }

    handleFormChange = (key, value) => {
        this.setState({
            payload: {
                ...this.state.payload,
                [key]: value
            }
        })
    }

    handleRangeFormChange = (key, subkey, value) => {
        let { payload } = this.state
        if (key === 'carat') {
            this.setState({
                carat: {
                    ...this.state.carat,
                    [subkey]: value
                }
            })
        } else {
            if (!payload.hasOwnProperty(key))
                payload = {
                    ...payload,
                    [key]: {
                        [subkey]: value
                    }
                }
            else
                payload = {
                    ...payload,
                    [key]: {
                        ...payload[key],
                        [subkey]: value
                    }
                }
        }
        this.setState({
            payload: {
                ...payload
            }
        })
    }

    getTags = (data, filter_on) => {
        const selectedData = this.state?.payload[filter_on];
        if (filter_on === 'carat') {
            data = [...this.state.inputCarat, ...data]
        }
        return <div className="col-md-12 btn-padding fixing-slider" key={filter_on}>
            <ToggleButtonGroup size="sm" onChange={val => this.handleFormChange(filter_on, val)} type="checkbox" value={this.state.payload[filter_on]} className="mb-2 fixing-class">
                {
                    data && [...data].map((item, index) => {
                        let variant = 'secondary'
                        if (selectedData) variant = selectedData.includes(item.value) ? 'primary' : 'secondary'
                        return <ToggleButton key={filter_on + index} variant={variant} value={item.value}>
                            {item.fields}
                        </ToggleButton>
                    })
                }
            </ToggleButtonGroup>
        </div>
    }

    getShapeSvg = (name) => {

    }

    getImageTag = (data, filter_on) => {
        const selectedData = this.state?.payload[filter_on];
        return <div className="col-md-12 btn-padding" key={filter_on}><ToggleButtonGroup onChange={val => this.handleFormChange(filter_on, val)} type="checkbox" defaultValue={[]} value={this.state.payload[filter_on]} className="mb-2 fixing-class shape-fix">
            {
                data && data.map((item, index) => {
                    let variant = 'secondary'
                    if (selectedData) variant = selectedData.includes(item.value) ? 'primary' : 'secondary'
                    return <ToggleButton style={{ wordBreak: 'break-word' }} key={filter_on + index} variant={variant} value={item.value}>
                        <div className="row">
                            <div className="col-12">
                                {
                                    item.img_id ? <Image src={`${storageUrl}${item.img_id}`} rounded />
                                        : <DiamondShape name={item.fields} />
                                }
                            </div>
                        </div>
                        {item.fields}
                    </ToggleButton>
                })
            }
        </ToggleButtonGroup></div>
    }

    addCarat = e => {
        const inputCarat = [...this.state.inputCarat];
        if (this.state.carat.from && this.state.carat.to) {
            inputCarat.push({
                fields: `${this.state.carat.from} - ${this.state.carat.to}`,
                value: `${this.state.carat.from} - ${this.state.carat.to}`,
            })
        }
        this.setState({
            inputCarat
        }, () => {
            this.caratForm.current.value = '';
            this.caratTo.current.value = '';
            let carat = this.state.payload.carat ? this.state.payload.carat : []
            carat = [
                ...carat,
                ...this.state.inputCarat.map(item => (item.value))
            ]
            this.setState((state) => ({
                payload: {
                    ...state.payload,
                    carat
                },
                carat: {}
            }))
        })

    }

    getRange = (data, filter_on) => {
        if (data.length === 0) return <></>
        let size = 'col-md-6 mb-3';
        if (data.length > 2) size = 'col-md-4 mb-3'
        return data.map((item, i) => {
            return <div className={size} key={i}>
                <div className="row">
                    {
                        item.fields ? <div className="col-md-12 padding-top-norm">
                            {item.fields}
                        </div> : null
                    }
                    <div className="col-md-5" style={{ padding: 0 }}>
                        <label
                            htmlFor="inputEmail3"
                            className="col-md-12 col-form-label"
                        >
                            From
                    </label>
                        <div className="col-md-12">
                            <input
                                type="number"
                                className="form-control"
                                placeholder=""
                                ref={filter_on === 'carat' ? this.caratForm : null}
                                onChange={e => { this.handleRangeFormChange(filter_on ? filter_on : item.value, 'from', e.target.value) }}
                                value={this.state.payload[filter_on || item.value]?.from}
                            />
                        </div>
                    </div>
                    <div className="col-md-5" style={{ padding: 0 }}>
                        <label
                            htmlFor="inputEmail3"
                            className="col-md-12 col-form-label"
                        >
                            To
                    </label>
                        <div className="col-md-12">
                            <input
                                type="number"
                                className="form-control"
                                placeholder=""
                                onBlur={e => filter_on || item.value === 'carat' ? this.addCarat(e) : null}
                                ref={filter_on === 'carat' ? this.caratTo : null}
                                onChange={e => { this.handleRangeFormChange(filter_on ? filter_on : item.value, 'to', e.target.value) }}
                                value={this.state.payload[filter_on || item.value]?.to}
                            />
                        </div>
                    </div>
                    {
                        filter_on === 'carat' && <div className="col-md-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <label
                                htmlFor="inputEmail3"
                                className="col-md-12 col-form-label"
                            >
                                &nbsp;
                            </label>
                            <Button
                                key={'primary3'}
                                variant={`outline-primary`}
                                className="text-capitalize ml-2"
                                onClick={e => this.addCarat()}
                            >
                                <i className="i-Add"></i>
                            </Button>
                        </div>
                    }
                </div>


            </div>
        })
    }

    getTitle = (data, filter_on) => {
        if (data.length === 0) return <></>

        return data.map((item, i) => {
            return <div className="col-md-6" key={i}>
                <div className="row">
                    <div className="col-md-12 mb-3 padding-top-norm">
                        {item.fields}
                    </div>
                    {this.prepareform({ field_value: item.field_value, filter_on })}
                </div>
            </div>
        })
    }

    getSelectEl = (data, filter_on) => {
        if (data.length === 0) return <></>;
        let select = <></>;
        let size = 'col-md-4 mb-3';
        if (data.length > 2) size = 'col-md-4 mb-3'
        switch (filter_on) {
            case 'countries':
            case 'location':
                select = <div key={filter_on} className={size}><select name="company_country_id" className="form-control" onChange={e => this.handleFormChange(filter_on, e.target.value)} value={this.state?.payload[filter_on]}>
                    <option>{this.props.countryloading ? 'Loading..' : 'Select Country'}</option>
                    {
                        this.props.countryList && this.props.countryList.map((country, index) => (
                            <option value={country.name} key={index}>{country.name}</option>
                        ))
                    }
                </select></div>
                break;
        }
        return select
    }

    getInputEl = (data, filter_on) => {
        if (data.length === 0) return <></>;
        let size = 'col-md-4 mb-3';
        return <div key={filter_on} className={size}>
            <input
                type="text"
                className="form-control"
                placeholder=""
                onChange={e => { this.handleFormChange(filter_on, e.target.value) }}
                value={this.state.payload[filter_on || item.value]}
            />
        </div>
    }

    prepareform = ({ field_value, filter_on }) => {
        if (!field_value) return <></>
        const formtypes = Object.keys(field_value)
        return formtypes.map(item => {
            switch (item) {
                case 'IMAGE_TAG':
                    return this.getImageTag(field_value[item], filter_on);
                case 'RANGE':
                    return this.getRange(field_value[item], filter_on,)
                case 'TAG':
                    return this.getTags(field_value[item], filter_on)
                case 'TITLE':
                    return this.getTitle(field_value[item], filter_on)
                case 'SELECT':
                    return this.getSelectEl(field_value[item], filter_on)
                case 'TEXT':
                    return this.getInputEl(field_value[item], filter_on)
                default: return <></>
            }
        })

    }

    handleSubmit = e => {
        this.props.postSavedSearch({
            title: moment().valueOf(),
            status: 'block',
            searchquery: JSON.stringify({ payload: this.state.payload, inputCarat: this.state.inputCarat })
        })
        this.props.history.push('/report', { filters: this.state.payload, inputCarat: this.state.inputCarat })
    }

    handleSaveSubmit = (values, { setSubmitting }) => {
        this.props.postSavedSearch({
            ...values,
            searchquery: JSON.stringify({ payload: this.state.payload, inputCarat: this.state.inputCarat })
        })
    };


    render() {
        const { searchType, loading, formList, searchList } = this.props;
        const { formData, openModal, payload } = this.state;
        if (loading)
            return <Loading></Loading>
        return (
            <div className="search-form">
                <Breadcrumb
                    routeSegments={[
                        { name: `${searchType} Diamonds`, path: "/search" }
                    ]}
                    extra={[
                        <select value={JSON.stringify(payload)} onChange={e => { this.setState({ payload: JSON.parse(e.target.value)?.payload || {}, inputCarat: JSON.parse(e.target.value)?.inputCarat || [] }) }} key={1} placeholder="Select saved search" className="form-control col-md-2" style={{ float: 'right' }}>
                            <option value={'{}'}>Select Saved Search</option>
                            {
                                searchList?.length > 0
                                    ? searchList.map((list, i) => {
                                        return <option key={i} value={list.data_str}>{list.title}</option>
                                    })
                                    : <option value="no">
                                        No Data
                                    </option>
                            }
                        </select>
                    ]}
                ></Breadcrumb>
                {
                    formList && formList.map((item, i) => {
                        if (item.fields === 'Make') {
                            const ActionButtons = [
                                {
                                    label: '3EX',
                                    onClick: e => this.setState({ payload: { ...this.state.payload, cut: ['EX'], polish: ['EX'], symmetry: ['EX'] } })
                                },
                                {
                                    label: '2EX',
                                    onClick: e => this.setState({ payload: { ...this.state.payload, cut: ['EX', 'VG'], polish: ['EX', 'VG'], symmetry: [] } })
                                },
                                {
                                    label: '3VG+',
                                    onClick: e => this.setState({ payload: { ...this.state.payload, cut: ['EX', 'VG'], polish: ['EX', 'VG'], symmetry: ['EX', 'VG'] } })
                                }
                            ]
                            return <div key={i} className="mb-3">
                                <div className="row">
                                    <div className="col-md-2 d-flex align-items-center">
                                        <h4 className="form-label" style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
                                            {item.fields}
                                        </h4>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <div className="col-md-12 btn-padding" key={item.filter_on}>
                                                {/* <ToggleButtonGroup size="sm" name="hello" onChange={val => { val(); return '' }} type="radio" value={this.state.payload['filter_on']} className="mb-2 ml-4"> */}
                                                <ToggleButtonGroup size="sm" name="hello" type="radio" value={this.state.payload['filter_on']} className="mb-2 ml-4">
                                                    {
                                                        ActionButtons && [...ActionButtons].map((item, index) => {
                                                            let variant = 'secondary'
                                                            return <ToggleButton className="text-primary" name={item.label} key={item.label + index} variant={variant} onClick={item.onClick}>
                                                                {item.label}
                                                            </ToggleButton>
                                                        })
                                                    }
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        return <SimpleCard key={i} className="mb-3">
                            <div className="row">
                                <div className="col-md-2 d-flex align-items-center">
                                    <h4 className="form-label">
                                        {item.fields}
                                    </h4>
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        {this.prepareform(item)}
                                    </div>
                                </div>
                            </div>
                        </SimpleCard>
                    }
                    )
                }
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="custom-margin-bottom">
                        </div>

                        <Button
                            key={'primary1'}
                            variant={`outline-primary`}
                            className="btn-rounded m-1 text-capitalize"
                            style={{ float: 'right' }}
                            onClick={e => this.setState({ payload: {}, inputCarat: [], carat: {} })}
                        >
                            Reset
                        </Button>
                        <Button
                            key={'primary2'}
                            variant={`outline-primary`}
                            className="btn-rounded m-1 text-capitalize"
                            style={{ float: 'right' }}
                            onClick={e => { this.setState({ openModal: true }) }}
                        >
                            {`Save Search`}
                        </Button>
                        <Button
                            key={'primary3'}
                            variant={`primary`}
                            className="btn-rounded m-1 text-capitalize"
                            style={{ float: 'right' }}
                            onClick={e => { this.handleSubmit(e) }}
                        >
                            Search
                        </Button>
                    </div>
                </div>
                <AppModal
                    show={openModal}
                    title={'Save & Search'}
                    footer={false}
                    centered={true}
                    size="md"
                    onHide={() => this.setState({ openModal: false })}
                >
                    <Formik
                        initialValues={this.state.saveFormValues}
                        validationSchema={formSchema}
                        onSubmit={this.handleSaveSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 form-group mb-3">
                                            <label htmlFor="title">Title:</label>
                                            <input
                                                id="title"
                                                name="title"
                                                className="form-control"
                                                placeholder="Enter your title"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values?.title}
                                            />
                                            {errors.title && touched.title && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.title}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            <Button
                                                variant={`secondary`}
                                                className="btn-rounded m-1 text-capitalize"
                                                style={{ float: 'right' }}
                                                type="button"
                                                onClick={() => this.setState({ openModal: false })}>
                                                Cancel
                                                </Button>
                                            <Button
                                                variant={`primary`}
                                                className="btn-rounded m-1 text-capitalize"
                                                style={{ float: 'right' }}
                                                type="submit">Save</Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                    </Formik>
                </AppModal>
            </div >
        )
    }
}

const formSchema = yup.object().shape({
    title: yup.string().required("title is required"),
});

const mapStateToProps = (state, ownProps) => ({
    loading: state.searchDiamond.loading,
    formList: state.searchDiamond.formList,
    searchList: state.searchDiamond.savedSearches,
    countryList: state.misc.countryList,
    countryloading: state.misc.countryloading,
});
const mapDispatchToProps = {
    getSearchDiamondForm,
    getSavedSearch,
    postSavedSearch,
    fetchCountries,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchDiamond));