const { apiAdapter } = require("./ApiAdapter")

const StoreFile = (data) => {
    return apiAdapter({
        url: 'file/store',
        method: 'POST',
        data,
        overrideHeader: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

module.exports = {
    StoreFile
}