import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import widgetsRoute from "./views/widgets/widgetsRoute";
import chartsRoute from "./views/charts/chartsRoute";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import pagesRoutes from "./views/pages/pagesRoutes";
import iconsRoutes from "./views/icons/iconsRoutes";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import { authRoles } from "./auth/authRoles";

import Dashboard from "./views/dashboard/dashboard/Dashboard";
import SearchReport from "./views/pages/search-report/SearchReport";
import Profile from "./views/pages/profile/Profile";
import SearchDiamond from "./views/pages/search-diamond/SearchDiamond";
import Login from "./views/pages/login/login";
import DiamondDetail from "./views/pages/diamond-detail/DiamondDetail";
import LayoutPage from "./views/pages/layout/LayoutPage";
import Compare from "./views/pages/compare/Compare";
import CheckEnquiry from "./views/pages/check-enquiry/CheckEnquiry";
import PublicDiamondDetail from "./views/pages/PublicDiamondDetail";
import MyOrders from "./views/pages/my-orders/MyOrders";
import Registration from "./views/pages/registration/registration";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />
  }
];

const publicRoutes = [
  {
    path: "/check/enquiry/:enquiryId",
    component: CheckEnquiry,
  },
  {
    path: "/diamond-details/:stoneid",
    component: PublicDiamondDetail,
  },
]

const userRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    auth: authRoles.user
  },
  {
    path: "/search",
    component: () => <SearchDiamond searchType="Search" />,
    auth: authRoles.user
  },
  {
    path: "/fancy",
    component: () => <SearchDiamond searchType="Fancy" />,
    auth: authRoles.user
  },
  {
    path: "/report",
    component: SearchReport,
    auth: authRoles.user
  },
  {
    path: "/diamond/:stoneid",
    component: DiamondDetail,
    auth: authRoles.user
  },
  {
    path: "/recommended",
    component: () => <SearchReport pageTitle={'Recommended Diamond'} />,
    auth: authRoles.user
  },
  {
    path: "/new-diamonds",
    component: () => <SearchReport pageTitle={'New Stock'} />,
    auth: authRoles.user
  },
  {
    path: "/best-diamonds",
    component: () => <SearchReport pageTitle={'Hot Deals'} />,
    auth: authRoles.user
  },
  {
    path: "/profile",
    component: Profile,
    auth: authRoles.user
  },
  {
    path: "/my-order",
    component: MyOrders,
    auth: authRoles.user
  },
  {
    path: "/layout",
    component: LayoutPage,
    auth: authRoles.user
  },
  {
    path: "/compare/:diamondIds",
    component: Compare,
    auth: authRoles.user
  },
]

const routes = [
  ...sessionsRoutes,
  ...publicRoutes,
  {
    path: "/login",
    component: Login
  },
  {
    path: "/registration/request",
    component: Registration
  },
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...userRoutes,

      ...dashboardRoutes,
      ...uiKitsRoutes,
      ...formsRoutes,
      ...widgetsRoute,
      ...chartsRoute,
      ...dataTableRoute,
      ...extraKitsRoutes,
      ...pagesRoutes,
      ...iconsRoutes,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...chatRoutes,
      ...taskManagerRoutes,
      ...calendarRoutes,
      ...ecommerceRoutes,
      ...contactRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
