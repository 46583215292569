import React, { Component } from 'react'
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createUsersDemand } from '../../redux/actions/UserActions'

const EnquirySchema = yup.object().shape({
    remark: yup
        .string()
        .required("remarks is required")
});

class RaiseDemand extends Component {
    state = {
        enquiryPayload: {
            remark: ''
        },
        openModal: false,
    }
    handleEnquirySubmit = (value, { isSubmitting }) => {
        const payload = {
            "requirements": value.remark
        }
        this.setState({
            demandLoader: true
        }, () => {
            this.props.createUsersDemand(payload).then(resp => {
                this.setState({
                    openModal: false
                })
            })
        })
    }
    render() {
        return (
            <div className={this.props.centered ? 'text-center' : ''}>
                <Button
                    key={2}
                    variant={`outline-primary`}
                    className={`btn-rounded m-1 text-capitalize right-float-patch ${this.props.className || ''}`}
                    style={{ float: this.props.noFloating ? 'unset' : 'right' }}
                    onClick={e => this.setState({ openModal: true })}
                >
                    {this.props.label ? this.props.label : 'Raise your Demand'}
                </Button>
                <AppModal
                    show={this.state.openModal}
                    title={'Create A Demand'}
                    size="md"
                    onHide={() => this.setState({ openModal: false })}
                >
                    <Formik
                        initialValues={this.state.enquiryPayload}
                        validationSchema={EnquirySchema}
                        onSubmit={this.handleEnquirySubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="remark">Give your Requirements</label>
                                        <textarea
                                            className="form-control position-relative"
                                            onChange={handleChange}
                                            name={'remark'}
                                        />
                                        {errors.remark && (
                                            <div className="text-danger mt-1 ml-2">
                                                {errors.remark}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-rounded btn-primary pull-right mt-2 ml-2"
                                        type="submit"
                                        style={{ float: 'right' }}
                                        disabled={isSubmitting}
                                    >
                                        {'Raise Demand'}
                                    </button>
                                    <button
                                        className="btn btn-rounded btn-secondary pull-right mt-2"
                                        type="button"
                                        style={{ float: 'right' }}
                                        onClick={() => this.setState({ openModal: false })}
                                    >
                                        {'Cancel'}
                                    </button>

                                </form>
                            )}
                    </Formik>
                </AppModal>
            </div>
        )
    }
}
const mapStateToProps = state => ({
});
export default connect(mapStateToProps, { createUsersDemand })(RaiseDemand);