/* eslint-disable */
import React, { Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

const Breadcrumb = ({ routeSegments, extra, switchlist_bc, patchingclass, isBackButton, history }) => {
  return (
    <Fragment>
      <div className="breadcrumb" style={{ display: 'flow-root' }}>
        {routeSegments ? (
          <div className={patchingclass} style={{ float: 'left' }}>
            <h1>
              {
                isBackButton
                  ? <svg viewBox="64 64 896 896" focusable="false" className="mr-2" style={{ cursor: 'pointer' }} onClick={e => history.goBack()} data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" /></svg>
                  : ''
              }
              {routeSegments[routeSegments.length - 1]["name"]}
            </h1>
          </div>
        ) : null}
        <div className="switchlist-action">
          {
            extra ? (
              <>
                {extra.map(item => item)}
              </>
            ) : <></>
          }
        </div>
      </div>
      <div className="extra-action main-content-wrap-avoide">
        {
          switchlist_bc ? (
            <>
              {switchlist_bc.reverse().map(item => item)}
            </>
          ) : <></>
        }
      </div>
      <div className="separator-breadcrumb border-top"></div>
    </Fragment >
  );
};

export default withRouter(Breadcrumb);
