const { apiAdapter } = require("./ApiAdapter")

const getFeaturedDiamonds = (params) => {
    return apiAdapter({
        url: 'diamond/featured',
        method: 'GET',
        params
    })
}

const getNewDiamonds = () => {
    return apiAdapter({
        url: 'diamond/new',
        method: 'GET',
    })
}

const getDiamondsById = (id) => {
    return apiAdapter({
        url: `diamond?id=${id}`,
        method: 'GET',
    })
}

const getRecommendedDiamonds = () => {
    return apiAdapter({
        url: 'user/recommend/diamonds',
        method: 'GET',
    })
}

const getSearchedDiamonds = (page, payload) => {
    return apiAdapter({
        url: 'diamond/search',
        method: 'POST',
        data: payload,
        params: { page }
    })
}

const createNewEnquiry = (payload) => {
    return apiAdapter({
        url: 'diamond/enquiry',
        method: 'POST',
        data: payload,
    })
}

const createNewPlaceOrder = (payload) => {
    return apiAdapter({
        url: 'diamond/order',
        method: 'POST',
        data: payload,
    })
}

const getLayouts = (page) => {
    return apiAdapter({
        url: 'layouts',
        method: 'GET',
        params: {
            page
        }
    })
}

const createDiamond = (data) => {
    return apiAdapter({
        url: 'diamond/create',
        method: 'POST',
        data
    })
}

const updatePlaceOrder = (payload) => {
    return apiAdapter({
        url: 'diamond/order/update',
        method: 'PUT',
        data: payload,
    })
}


module.exports = {
    getFeaturedDiamonds,
    getRecommendedDiamonds,
    getSearchedDiamonds,
    getNewDiamonds,
    createNewEnquiry,
    createNewPlaceOrder,
    getDiamondsById,
    getLayouts,
    createDiamond,
    updatePlaceOrder
}