import Axios from "axios";
import moment from "moment";
import { isCurrencyConvertionActive, currencyInfo } from './environment/env';

export const FILE_FORMATS = ['.jpg', '.png', '.jpeg', '.bmp', '.gif'];
export const VIDEO_FORMATS = ['.avi', '.flv', '.mkv', '.mov', '.mp4', '.webm', '.wmv'];

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  var definitions = search.split("&");
  definitions.forEach(function (val, key) {
    var parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(" ");
}

export function initCodeViewer() {
  if (!document) return;
  const pre = document.getElementsByTagName('pre');
  if (!pre.length) return;
  Array.prototype.map.call(pre, p => {
    // console.log(p);
    p.classList.add('collapsed');
    p.addEventListener('click', (e) => {
      console.log(e.target);
      e.target.classList.remove('collapsed');
    })
  });

  // pre.map(p => {
  //   console.log(p)
  // })

}

export function OpenNewWindow(url, target, option = '') {
  window.open(url, target, option)
}

export function downloadFile(file_path, target = '_self') {
  if (!file_path) {
    return;
  }
  var a = document.createElement("a");
  a["href"] = file_path;
  a["download"] = file_path.substr(file_path.lastIndexOf("/") + 1);
  a['target'] = target
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function webShare({ title, text, url }) {
  try {
    await navigator.share({ title, text, url });
    console.log('Successfully sent share');
  } catch (error) {
    console.log('Error sharing: ' + error);
  }
  // setShareButtonsEnabled(true);
}

export const setUpCurrencyConvertion = () => {
  if (!localStorage.getItem('currency'))
    localStorage.setItem('currency', currencyInfo.base)
  // console.log(window.fx.convert(1000, {to: 'EUR'}))
  if (!isCurrencyConvertionActive) return
  window.fx.settings = {
    from: "USD",
    to: "USD"
  };
  // console.log(response.data);
  window.fx.base = currencyInfo.base;
  window.fx.rates = currencyInfo.rates
  const requestURL = 'https://api.exchangerate.host/latest';
  if (currencyInfo.useApiCall)
    Axios.get(requestURL, { params: { base: currencyInfo.base, symbols: currencyInfo.activeCurrency.join(',') } })
      .then(response => {
        if (response.data) {
          window.fx.settings = {
            from: "USD",
            to: "USD"
          };
          // console.log(response.data);
          window.fx.base = response.data.base;
          window.fx.rates = response.data.rates
        }
      }).catch(err => {
        window.fx.settings = {
          from: "USD",
          to: "USD"
        };
        // console.log(response.data);
        window.fx.base = currencyInfo.base;
        window.fx.rates = currencyInfo.rates
      })
}

export const currencyConterver = (amt) => {
  if (!isCurrencyConvertionActive) return 'USD ' + amt;
  const to = localStorage.getItem('currency') ?? 'USD'
  const Amount = window?.fx?.convert(amt, { to }).toFixed(2);
  return Amount ? `${to} ${Amount}` : undefined
}

export const currencyConvertedValue = (amt, opt) => {
  if (!isCurrencyConvertionActive) return amt;
  const to = getCurrentCurrency() ?? 'USD'
  const Amount = window?.fx?.convert(amt, opt ? opt : { to }).toFixed(2);
  return Amount
}

export const getCurrentCurrency = () => (localStorage.getItem('currency'));
export const setCurrentCurrency = (val) => (localStorage.setItem('currency', val));

const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
}

const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded => encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
}

export const encode = cipher('jinagna');
export const decode = decipher('jinagna');