import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  GET_USERS_BEGIN,
  GET_USERS_SUCESS,
  GET_USERS_FAILED,
  GET_USER_ORDERS_BEGIN,
  GET_USER_ORDERS_SUCESS,
  GET_USER_ORDERS_FAILED
} from "../actions/UserActions";

const initialState = {};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case GET_USERS_BEGIN: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_USERS_SUCESS: {
      return {
        ...state,
        loading: false,
        userList: { ...action.payload }
      };
    }
    case GET_USERS_FAILED: {
      return {
        ...state,
        loading: false,
        userList: {},
        userError: action.payload
      };
    }
    case GET_USER_ORDERS_BEGIN: {
      return {
        ...state,
        userOrdersLoading: true
      };
    }
    case GET_USER_ORDERS_SUCESS: {
      return {
        ...state,
        userOrdersLoading: false,
        userOrdersData: action.payload
      };
    }
    case GET_USER_ORDERS_FAILED: {
      return {
        ...state,
        userOrdersLoading: false,
        userOrdersData: [],
        userOrdersError: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
