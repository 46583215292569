import { getFeaturedDiamonds, getRecommendedDiamonds, getSearchedDiamonds, getNewDiamonds, createNewEnquiry, createNewPlaceOrder, getDiamondsById, getLayouts, createDiamond, updatePlaceOrder } from '../../services/DiamondService';
import { NotificationManager } from 'react-notifications';

export const GET_FEATURED_DIAMOND_BEGIN = 'GET_FEATURED_DIAMOND_BEGIN';
export const GET_FEATURED_DIAMOND_SUCESS = 'GET_FEATURED_DIAMOND_SUCESS';
export const GET_FEATURED_DIAMOND_FAILED = 'GET_FEATURED_DIAMOND_FAILED';

export const GET_DIAMOND_BY_ID_BEGIN = 'GET_DIAMOND_BY_ID_BEGIN';
export const GET_DIAMOND_BY_ID_SUCESS = 'GET_DIAMOND_BY_ID_SUCESS';
export const GET_DIAMOND_BY_ID_FAILED = 'GET_DIAMOND_BY_ID_FAILED';

export const GET_NEW_DIAMOND_BEGIN = 'GET_NEW_DIAMOND_BEGIN';
export const GET_NEW_DIAMOND_SUCESS = 'GET_NEW_DIAMOND_SUCESS';
export const GET_NEW_DIAMOND_FAILED = 'GET_NEW_DIAMOND_FAILED';

export const CREATE_ENQUIRY_BEGIN = 'CREATE_ENQUIRY_BEGIN';
export const CREATE_ENQUIRY_SUCESS = 'CREATE_ENQUIRY_SUCESS';
export const CREATE_ENQUIRY_FAILED = 'CREATE_ENQUIRY_FAILED';

export const GET_LAYOUTS_BEGIN = 'GET_LAYOUTS_BEGIN';
export const GET_LAYOUTS_SUCESS = 'GET_LAYOUTS_SUCESS';
export const GET_LAYOUTS_FAILED = 'GET_LAYOUTS_FAILED';

export const CREATE_DIAMOND_BEGIN = 'CREATE_DIAMOND_BEGIN';
export const CREATE_DIAMOND_SUCESS = 'CREATE_DIAMOND_SUCESS';
export const CREATE_DIAMOND_FAILED = 'CREATE_DIAMOND_FAILED';


export const fetchFeaturedDiamond = (payload = {}) => dispatch => {
    dispatch({
        type: GET_FEATURED_DIAMOND_BEGIN
    });
    getFeaturedDiamonds(payload).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: { data: [] }
            })
        }
    })
};

export const createNewDiamond = (payload) => dispatch => {
    return createDiamond(payload).then((resp) => {
        if (resp?.data?.data) {
            NotificationManager.success('Record updated successfully')
            return resp?.data?.data
        } else {
            NotificationManager.success('Fail to update record, Please try again.')
        }
    })
};

export const fetchDiamondById = (id) => dispatch => {
    dispatch({
        type: GET_DIAMOND_BY_ID_BEGIN
    });
    getDiamondsById(id).then((resp) => {
        if (resp?.data?.data?.data?.length) {
            dispatch({
                type: GET_DIAMOND_BY_ID_SUCESS,
                payload: resp?.data?.data?.data
            })
        } else {
            dispatch({
                type: GET_DIAMOND_BY_ID_FAILED,
                payload: []
            })
        }
    }).catch(e => {
        dispatch({
            type: GET_DIAMOND_BY_ID_FAILED,
            payload: []
        })
    })
};

export const fetchNewDiamond = (type) => dispatch => {
    dispatch({
        type: GET_NEW_DIAMOND_BEGIN
    });
    getNewDiamonds().then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: { data: [] }
            })
        }
    })
};

export const fetchSearchedDiamond = (page = 1, payload = {}) => dispatch => {
    dispatch({
        type: GET_FEATURED_DIAMOND_BEGIN
    });
    getSearchedDiamonds(page, payload).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: []
            })
        }
    })
};

export const fetchRecommendedDiamond = (type) => dispatch => {
    dispatch({
        type: GET_FEATURED_DIAMOND_BEGIN
    });
    getRecommendedDiamonds().then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: GET_FEATURED_DIAMOND_SUCESS,
                payload: []
            })
        }
    })
};

export const createDiamondEnquiry = (payload) => dispatch => {
    dispatch({
        type: CREATE_ENQUIRY_BEGIN
    });
    createNewEnquiry(payload).then((resp) => {
        if (resp?.data?.success) {
            dispatch({
                type: CREATE_ENQUIRY_SUCESS,
                payload: resp?.data
            })
        } else {
            dispatch({
                type: CREATE_ENQUIRY_FAILED,
                payload: {}
            })
        }
    })
};

export const updateDiamondPlaceOrder = (payload) => dispatch => {
    return updatePlaceOrder(payload).then((resp) => {
        return resp
    })
};

export const createDiamondPlaceOrder = (payload) => dispatch => {
    dispatch({
        type: CREATE_ENQUIRY_BEGIN
    });
    createNewPlaceOrder(payload).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: CREATE_ENQUIRY_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: CREATE_ENQUIRY_SUCESS,
                payload: []
            })
        }
    })
};

export const fetchLayouts = (page = 1) => dispatch => {
    dispatch({
        type: GET_LAYOUTS_BEGIN
    });
    getLayouts(page).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_LAYOUTS_SUCESS,
                payload: resp?.data?.data
            })
        } else {
            dispatch({
                type: GET_LAYOUTS_SUCESS,
                payload: []
            })
        }
    })
};