module.exports = {
    apiUrl: 'https://api.jinagna.co/api/',
    storageUrl: 'https://api.jinagna.co',
    adminUrl: 'https://admin.jinagna.co',
    appUrl: 'https://trade.jinagna.co',
    envType: 'prod',
    whatsappNo: +919265929439,
    contactInfo: {
        name: 'Pashva Shah',
        email: 'support@jinagna.co',
        mobile: '+91 9265929439',
        whatsappNo: '+91 9265929439'
    },
    banKInfo: {
        benificeary: 'SAMAY JEWEL FZE',
        bankName: 'RAK BANK',
        branch: 'DEIRA, GOLD SOUQ, DUBAI',
        aed_iban: 'AE12040000 0252569062001',
        swift: 'NRAKAEAK',
        usdAcc: '252569062002',
        usd_iban: 'AE820400000252569062002',
        usd_swift: 'SCBLUS33'
    },
    isCurrencyConvertionActive: true,
    currencyInfo: {
        useApiCall: false,
        base: 'USD',
        activeCurrency: ['USD', 'AED'],
        rates: { "AED": 3.67301, "USD": 1 }
    }
}