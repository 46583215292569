import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from '@gull'
import Spin from '@gull/components/Spin'
import ReactPaginate from 'react-paginate'
import { OverlayTrigger, Tooltip, Badge, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Table from 'app/GullLayout/SharedComponents/table/Table'
import { fetchUserOrders } from '../../../redux/actions/UserActions';
import { updateDiamondPlaceOrder } from '../../../redux/actions/DiamondActions';
import AppModal from 'app/GullLayout/SharedComponents/modal/Modal'
import { NotificationManager } from 'react-notifications'
import { currencyConterver } from '@utils'

class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Action',
                    dataIndex: 'action',
                    render: (data, row) => {
                        return <>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Send Whatsapp message
                                    </Tooltip>
                                }
                            >
                                <i onClick={e => this.openWhatsapp(row)} className="fab fa-whatsapp ml-1"></i>
                            </OverlayTrigger>
                            {/* {
                                (Number(row.status) === 1 || Number(row.status) === 2 && false) && <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Cancel Order
                                    </Tooltip>
                                    }
                                >
                                    <i onClick={e => this.toggleAlertBox(row, true)} className="fa fa-times ml-1"></i>
                                </OverlayTrigger>
                            } */}
                        </>
                    }
                },
                {
                    title: 'Order ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Ordered Stone No',
                    dataIndex: 'diamonds',
                    render: (data) => (data?.length ? <Link to={`/diamond/${window.btoa(data[0].id)}`}>{data[0].stone_id}</Link> : '-')
                },
                {
                    title: 'Remark',
                    dataIndex: 'remark'
                },
                {
                    title: 'Pending Amount',
                    dataIndex: 'total_pending',
                    render: (data, row) => currencyConterver((Number(data) - Number(row.total_paid)))
                },
                {
                    title: 'Paid Amount',
                    dataIndex: 'total_paid',
                    render: (data) => currencyConterver(data)
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: data => Number(data) === 1
                        ? <Badge key={1} className={`badge-warning text-white p-2`}>
                            {'PENDING'}
                        </Badge>
                        : Number(data) === 3
                            ? <Badge key={0} className={`badge-danger p-2`}>
                                {'REJCTED'}
                            </Badge>
                            : Number(data) === 4
                                ? <Badge key={4} className={`badge-danger p-2`}>
                                    {'CANCELED'}
                                </Badge>
                                : <Badge key={2} className={`badge-success p-2`}>
                                    {'ACCEPTED'}
                                </Badge>
                },
                {
                    title: 'Last Update On',
                    dataIndex: 'updated_at'
                },
                {
                    title: 'Order Date',
                    dataIndex: 'created_at'
                },
            ],
            isOpenAlertBox: false,
            selectedRow: {},
            page: 1,
            actionLoading: false
        }
        this.userid = JSON.parse(localStorage.getItem('auth_user')).id
        this.props.fetchUserOrders({ page: 1, userid: this.userid }, true)
    }
    toggleAlertBox = (row, isRejectModal = false) => {
        this.setState({
            selectedRow: row,
            isOpenAlertBox: !this.state.isOpenAlertBox,
            isRejectModal
        })
    }

    onPageChange = page => {
        this.setState({
            page
        }, () => this.props.fetchUserOrders({ page: (page + 1), userid: this.userid }, true))

    }

    openWhatsapp = row => {
        if (!row.user.length) {
            NotificationManager.error('whatsapp no is not available');
            return
        }

        window.open(`https://api.whatsapp.com/send?phone=${row.user[0].whatsapp_no}&app_absent=true`)
    }

    confirmAction = () => {
        if (!this.state.selectedRow) {
            NotificationManager.error('No order selected');
            return 0
        }
        const row = this.state.selectedRow;
        this.props.updateDiamondPlaceOrder({ id: row.id, status: 4 }).then(resp => {
            if (resp?.data?.data) {
                this.setState({
                    isOpenAlertBox: false,
                    actionLoading: false
                })
                NotificationManager.success('Record Updated', 'Success')
                this.props.fetchUserOrders({ page: this.state.page, userid: this.userid }, true)
            } else {
                this.setState({
                    actionLoading: false,
                    isOpenAlertBox: false,
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                actionLoading: false
            })
            NotificationManager.error(err?.data?.error ? err.data.error : 'Could\'nt able to Update Record, Try again')
        })
    }
    render() {
        const { columns, isOpenAlertBox, actionLoading } = this.state;
        const { orderData, orderLoading, currentPage, totalRow, perPage } = this.props;
        return (
            <div className="my-order">
                <Breadcrumb
                    routeSegments={[
                        { name: "My Order", path: "/my-order" }
                    ]}
                ></Breadcrumb>
                <Spin loading={orderLoading}>
                    {/* {
                        !!orderSummary && <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h6>Total Pending Amount: <span className="text-success">{orderSummary.total_pending}</span></h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h6>Total Paid Amount : <span className="text-danger">{orderSummary.total_paid}</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                    <Table
                        dataSource={orderData}
                        bordered={true}
                        columns={columns}
                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                        paginate={false}
                    />
                    <div className="sticky-footer">
                        <div className="container">
                            <div className="d-flex justify-content-center mt-3 mb-3">
                                <ReactPaginate
                                    previousLabel={<i className="i-Previous"></i>}
                                    nextLabel={<i className="i-Next1"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={(Math.ceil((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={5}
                                    onPageChange={(page) => this.onPageChange(page.selected)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>
                <AppModal
                    show={isOpenAlertBox}
                    title={'Alert!'}
                    size="md"
                    centered
                    onHide={() => this.setState({ isOpenAlertBox: false })}
                    footer={
                        <Spin loading={actionLoading}>
                            <Button className="ml-1" onClick={e => this.confirmAction()}>YES</Button>
                            <Button onClick={() => this.setState({ isOpenAlertBox: false })} className="ml-1">NO</Button>
                        </Spin>
                    }
                >
                    Are you sure, you want to Cancel this order?
                </AppModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderData: state.user?.userOrdersData?.data?.data ? state.user?.userOrdersData?.data?.data.length ? state.user?.userOrdersData?.data?.data : Object.keys(state.user?.userOrdersData?.data?.data).map(item => (state.user?.userOrdersData?.data?.data[item])) : [],
    orderSummary: state.user?.userOrdersData?.summary,
    orderLoading: state.user?.userOrdersLoading,
    currentPage: state.user?.userOrdersData?.data?.current_page,
    totalRow: state.user?.userOrdersData?.data?.total,
    perPage: state.user?.userOrdersData?.data?.per_page,
})

const mapDispatchToProps = {
    fetchUserOrders,
    updateDiamondPlaceOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders)