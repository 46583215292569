const { apiAdapter } = require("./ApiAdapter")

const getCountries = (params) => {
    return apiAdapter({
        url: 'misc/countries',
        method: 'GET',
        params
    })
}
const getStates = (params) => {
    return apiAdapter({
        url: 'misc/states',
        method: 'GET',
        params
    })
}
const getCities = (params) => {
    return apiAdapter({
        url: 'misc/cities',
        method: 'GET',
        params
    })
}
const userDashboardStats = () => {
    return apiAdapter({
        url: 'misc/stats/user/dashboard',
        method: 'GET'
    })
}
const getOrders = (params) => {
    return apiAdapter({
        url: 'diamond/orders',
        method: 'GET',
        params
    })
}
const getUsers = (params) => {
    return apiAdapter({
        url: 'users',
        method: 'GET',
        params
    })
}
const createUser = (data) => {
    return apiAdapter({
        url: 'user/create',
        method: 'POST',
        data
    })
}
const createApprovalUser = (data) => {
    return apiAdapter({
        url: 'user/approval/create',
        method: 'POST',
        data
    })
}
const updateUser = (data) => {
    return apiAdapter({
        url: 'user/update',
        method: 'PUT',
        data
    })
}
const createUserDemand = (data) => {
    return apiAdapter({
        url: 'user/create/demand',
        method: 'POST',
        data
    })
}


module.exports = {
    getUsers,
    createUser,
    updateUser,
    getCountries,
    getStates,
    getCities,
    userDashboardStats,
    getOrders,
    createUserDemand,
    createApprovalUser
}