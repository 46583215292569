import React, { Component } from "react";
import './style.scss';
import { Formik } from "formik";
import * as yup from "yup";
import { loginWithEmailAndPassword } from "app/redux/actions/LoginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spin from "@gull/components/Spin";

const SigninSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email")
        .required("email is required"),
    password: yup
        .string()
        .min(6, "Password must be 6 character long")
        .required("password is required")
});

class Login extends Component {
    state = {
        email: "",
        password: ""
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (value, { isSubmitting }) => {
        this.props.loginWithEmailAndPassword(value);
    };

    render() {
        return (
            <div
                className="auth-layout-wrap login"
                style={{
                    backgroundImage: "url()",
                    backgroundSize: 'cover'
                }}
            >
                <div className="auth-content">
                    <div className="card o-hidden">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="p-4">
                                    <h1 className="mb-3 text-18">LOGIN</h1>
                                    <Formik
                                        initialValues={this.state}
                                        validationSchema={SigninSchema}
                                        onSubmit={this.handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting
                                        }) => (
                                                <Spin loading={this.props.loading} >

                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email address</label>
                                                            <input
                                                                className="form-control position-relative"
                                                                type="email"
                                                                name="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                            />
                                                            {errors.email && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.email}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="password">Password</label>
                                                            <input
                                                                className="form-control"
                                                                type="password"
                                                                name="password"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.password}
                                                            />
                                                            {errors.password && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.password}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <button
                                                            className="btn btn-rounded btn-primary btn-block mt-2"
                                                            type="submit"
                                                        >
                                                            LOGIN
                                                    </button>
                                                    </form>
                                                </Spin>
                                            )}
                                    </Formik>

                                    <div className="mt-3 text-center">
                                        <Link to="/session/forgot-password" className="text-muted">
                                            <u>Forgot Password?</u>
                                        </Link>
                                    </div>
                                    <div className="mt-3 text-center">Don't have account yet? <Link className="btn btn-rounded btn-outline-primary btn-block mt-2" to="registration/request"> Create Account </Link></div>
                                </div>
                            </div>
                            <div
                                className="col-md-6 text-center "
                                style={{
                                    backgroundColor: 'rgb(239 239 239)'
                                }}
                            >
                                <div className="auth-logo text-center mt-4 p-4">
                                    <img src={`${process.env.PUBLIC_URL}/assets/logo/full-logo.png`} alt="logo" />
                                </div>
                                {/* <p className="m-0 p-0 text-font h3">JINAGNA</p>
                                <p className="m-2 p-0 h5">The House of Diamond</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    user: state.user,
    loading: state.login.loading
});

export default connect(mapStateToProps, {
    loginWithEmailAndPassword
})(Login);
