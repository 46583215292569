import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { createNewApprovalUser } from "app/redux/actions/UserActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spin from "@gull/components/Spin";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import SweetAlert from "sweetalert2-react";
import './style.scss';

const SigninSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email")
        .required("email is required"),
    name: yup
        .string()
        .required("Name is required"),
    mobileNo: yup
        .string()
        .min(10, "mobile number must be 10 character long")
        .max(10, "mobile number must be 10 character long")
        .required("mobile number is required"),
    companyName: yup
        .string()
        .required("company name is required"),
});

class Registration extends Component {
    state = {
        name: "",
        email: "",
        mobileNo: "",
        companyName: "",
        owner: undefined,
        card: undefined,
        errors: {},
        loading: false,
        openSucessModal: false
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (value, action) => {
        let isValid = true;
        if (!this.state.owner) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    owner: "Owner proof is required"
                },
                loading: false
            })
            isValid = false
            return
        }
        if (!this.state.card) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    card: "visiting card is required"
                },
                loading: false
            })
            isValid = false
            return
        }

        if (isValid) {
            const payload = {
                ...value,
                owner_proof: this.state.owner,
                visiting_card: this.state.card
            }
            this.props.createNewApprovalUser(payload).then(resp => {
                this.setState({
                    loading: false,
                    owner_proof: undefined,
                    visiting_card: undefined,
                    openSucessModal: true
                })
            })
        }
    };

    onUploadFinish = (url, type) => {
        let key = "owner";
        switch (type) {
            case "proof/owner_proof": key = "owner"; break;
            case "proof/visiting_card": key = "card"; break;
            default: key = "owner";
        }
        this.setState({
            [key]: url,
            errors: {
                ...this.state.errors,
                [key]: undefined
            },
        })
    }

    render() {
        return (
            <div
                className="auth-layout-wrap registration"
                style={{
                    backgroundImage: "url()",
                    backgroundSize: 'cover',
                }}
            >
                <div className="auth-content w-100" style={{ maxWidth: '100%' }}>   
                    <div className="card o-hidden">
                        <div className="row">
                            <div
                                className="col-md-8 text-center full-height"
                                style={{
                                    backgroundColor: 'rgb(239 239 239)'
                                }}
                            >
                                <div className="auth-logo text-center p-5">
                                    <img src={`${process.env.PUBLIC_URL}/assets/logo/full-logo.png`} alt="logo" />
                                </div>
                                {/* <p className="m-0 p-0 text-font h3">JINAGNA</p>
                                <p className="m-2 p-0 h5">The House of Diamond</p> */}
                                <div className="mt-3 mb-2 text-center">
                                    <Link to="/login" className="text-muted">
                                        <u>Already a member? Click here</u>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 overflow-patch full-height">
                                <div className="p-4 overflow-patch">
                                    <h1 className="mb-3 text-18">REGISTRATION</h1>
                                    <Formik
                                        initialValues={this.state}
                                        validationSchema={SigninSchema}
                                        onSubmit={(value, action) => this.setState({ loading: true }, () => this.handleSubmit(value, action))}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting
                                        }) => (
                                                <Spin loading={this.state.loading} >

                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Full Name <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="name"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.name}
                                                            />
                                                            {errors.name && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.name}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email address <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control position-relative"
                                                                type="email"
                                                                name="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                            />
                                                            {errors.email && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.email}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="mobileNo">Mobile Number <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="mobileNo"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.mobileNo}
                                                            />
                                                            {errors.mobileNo && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.mobileNo}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="mobileNo">Company Name <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyName"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.companyName}
                                                            />
                                                            {errors.companyName && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {errors.companyName}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="mobileNo">Owner Proof <span className="text-danger">*</span></label>
                                                            <FileUpload initalData={this.state?.owner} isServerUpload={true} allowedFiles={['.png', '.jpg', '.jpeg']} isMultiple={false} onUploadFinish={this.onUploadFinish} filepath="proof/owner_proof" />
                                                            {this.state.errors?.owner && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {this.state.errors.owner}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="card">Visiting Card <span className="text-danger">*</span></label>
                                                            <FileUpload initalData={this.state?.card} isServerUpload={true} allowedFiles={['.png', '.jpg', '.jpeg']} isMultiple={false} onUploadFinish={this.onUploadFinish} filepath="proof/visiting_card" />
                                                            {this.state.errors?.card && (
                                                                <div className="text-danger mt-1 ml-2">
                                                                    {this.state.errors.card}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <button
                                                            className="btn btn-rounded btn-primary btn-block mt-2 mb-4"
                                                            type="submit"
                                                        >
                                                            SEND REQUEST
                                                    </button>
                                                    </form>
                                                </Spin>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.openSucessModal}
                    title="Success"
                    type="success"
                    text="Request is send to admin. Soon they will contact you back. Thank you."
                    onConfirm={() => this.setState({
                        openSucessModal: false
                    }, () => {
                        window.location.reload()
                    })}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    user: state.user,
    loading: state.login.loading
});

export default connect(mapStateToProps, {
    createNewApprovalUser
})(Registration);
