import {
    GET_SEARCH_DIAMOND_FORM_BEGIN,
    GET_SEARCH_DIAMOND_FORM_SUCESS,
    GET_SEARCH_DIAMOND_FORM_FAILED,
    GET_SAVED_SEARCH_BEGIN,
    GET_SAVED_SEARCH_SUCESS,
    GET_RECENT_SEARCH_BEGIN,
    GET_RECENT_SEARCH_SUCESS,
} from "../actions/SearchDiamondAction";

const initialState = {
    loading: false,
    formList: []
};

const searchDiamondReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SEARCH_DIAMOND_FORM_BEGIN: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_SEARCH_DIAMOND_FORM_SUCESS: {
            return {
                ...state,
                loading: false,
                formList: action.payload
            };
        }
        case GET_SEARCH_DIAMOND_FORM_FAILED: {
            return {
                ...state,
                loading: false,
                formList: action.payload
            };
        }
        case GET_SAVED_SEARCH_BEGIN: {
            return {
                ...state,
                savedSeachloading: true,
                savedSearches: []
            };
        }
        case GET_SAVED_SEARCH_SUCESS: {
            return {
                ...state,
                savedSeachloading: false,
                savedSearches: action.payload
            };
        }
        case GET_RECENT_SEARCH_BEGIN: {
            return {
                ...state,
                recentSeachloading: true,
                recentSearches: []
            };
        }
        case GET_RECENT_SEARCH_SUCESS: {
            return {
                ...state,
                recentSeachloading: false,
                recentSearches: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export default searchDiamondReducer;